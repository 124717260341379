<template>
  <b-container fluid>
   <BADCCertificateViewBn :id="id" :circular_memo_no="circular_memo_no"/>
  </b-container>
</template>
<script>
import BADCCertificateViewBn from './BADCCertificateViewBn'

export default {
  components: {
    BADCCertificateViewBn
  },
  data () {
    return {
      id: this.$route.query.id,
      circular_memo_no: this.$route.query.circular_memo_no
    }
  }
}
</script>
